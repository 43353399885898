input[type=checkbox]~label,
input[type=radio]~label {
    height: 20px;
    width: 100%;
    display: inline-block;
    padding: 0 0 0 0px;
    margin: 0px 10px 0px 0px;
    cursor: pointer;
}

input[type=checkbox]~label:before,
input[type=radio]~label:before {
    position: absolute;
    display: inline-block;
    background-size: cover;
    content: " ";
    width: 20px;
    height: 20px;
}

.checkbox-wrapper p,
.radio-wrapper p {
    font-size: 16px;
    display: inline-block;
    position: relative;
    margin-left: 30px;
    cursor: pointer;
}

.checkbox-wrapper input, 
.radio-wrapper input {
    display: none;
}

input[type=checkbox]~label:before {
    background-image: url('../../media/forms/checkbox/checkbox.png');
}

input[type=checkbox]:checked~label:before {
    background-image: url('../../media/forms/checkbox/checkbox_active.png');
}

input[type=checkbox]:disabled~label:before {
    background-image: url('../../media/forms/checkbox/checkbox_disabled.png');
    cursor: default;
}

input[type=checkbox]:checked:disabled~label:before {
    background-image: url('../../media/forms/checkbox/checkbox_disabled_checked.png');
    cursor: default;
}